// extracted by mini-css-extract-plugin
export var pressCard__date = "cc_pG";
export var pressCard__description = "cc_pF";
export var pressCard__image_large = "cc_pw";
export var pressCard__image_small = "cc_px";
export var pressCard__info = "cc_py";
export var pressCard__info_large = "cc_pz";
export var pressCard__info_small = "cc_pB";
export var pressCard__large = "cc_pH";
export var pressCard__link = "cc_pv";
export var pressCard__post = "cc_pt";
export var pressCard__small = "cc_ps";
export var pressCard__source = "cc_pC";
export var pressCard__title = "cc_pD";